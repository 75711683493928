<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Mill </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="mill"
                                    :items="mills" default="" item-value="mill_id" item-text="mill_name" label="Mill"
                                    :disabled="isMillDisabled" :auto-select-first="isMillDisabled" @change="(event) => getMachType(event)">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Mach Type </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="machType"
                                    :items="machTypes" default="" item-value="mach_type" item-text="mach_type" label="Mach Type"
                                    :disabled="isMachTypeDisabled" :auto-select-first="isMachTypeDisabled" @change="(event) => getMachId(event)">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Mach Id </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="machId"
                                    :items="machIds" default="" item-value="mach_id" item-text="mach_id" label="Mach Id"
                                    :disabled="isMachIdDisabled" :auto-select-first="isMachIdDisabled" @change="(event) => getMaintSection(event)">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Section </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="section"
                                    :items="sections" default="" item-value="section_id" :item-text="item => item.section_id +' - '+ item.descr.trim()"
                                    label="Section" :disabled="isSectionDisabled" :auto-select-first="isSectionDisabled" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Level </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="level"
                                    :items="levels" default="" item-value="id" :item-text="item => item.id +' - '+ item.descr"
                                    label="Level" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Active </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="active"
                                    :items="actives" default="" label="Active" clearable>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-3">
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" style="padding: 19px;" color="info" elevation="2" small block @click="getHeader('search', [])"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" style="padding: 19px;" color="success" elevation="2" small block @click="openDialog('new', [])"><v-icon>mdi-plus</v-icon> Add Config</v-btn>
                            </v-col>
                            <!-- <v-col class="col-6" sm="6" md="2">
                                <v-btn small block color="#005c37" class="py-5 mr-3 text-white border-12">
                                    <v-icon>mdi-file-excel</v-icon>
                                    <download-excel class="text" :fetch="exportExcel" :fields="headersColumn"
                                        :before-generate="startDownload" :before-finish="finishDownload">
                                    </download-excel>
                                </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-dialog v-model="dialogConfig" class="rounded-xl" max-width="500px" transition="dialog-bottom-transition">
                <v-toolbar dark
                    color="primary">
                    <v-btn icon
                        dark @click=closeDialog()>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{dialogConfigTitle}} </v-toolbar-title>
                </v-toolbar>
                <v-row>
                    <v-card outlined class="mb-4 mt-4 ml-2 mr-2" >
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-6" sm="6" md="6">
                                    <p class="blue-lcd ma-0 pa-0">Mill <strong style="color:red;">*</strong><span></span></p>
                                    <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="millDialog"
                                        :items="millsDialog" default="" item-value="mill_id" item-text="mill_name" label="Mill"
                                        @change="(event) => getMaintSectionDialog(event, '')"
                                        :disabled="isMillDialogDisabled" :auto-select-first="isMillDialogDisabled" >
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="6">
                                    <p class="blue-lcd ma-0 pa-0">Section <strong style="color:red;">*</strong><span></span></p>
                                    <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="sectionDialog"
                                        :items="sectionsDialog" default="" item-value="section_id" :item-text="item => item.section_id +' - '+ item.descr.trim()"
                                        label="Section" clearable :disabled="isSectionDialogDisabled" :auto-select-first="isSectionDialogDisabled">
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="6">
                                    <p class="blue-lcd mb-0 pa-0">Level <strong style="color:red;">*</strong></p>
                                    <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="levelDialog"
                                        :items="levels" default="" item-value="id" :item-text="item => item.id +' - '+ item.descr"
                                        label="Level" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="6">
                                    <p class="blue-lcd mb-0 pa-0">NIK <strong style="color:red;">*</strong></p>
                                    <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="nikDialog"
                                        :items="niksDialog" default="" item-value="idemployee" :item-text="item => item.idemployee.trim() +' - '+ item.name.trim()"
                                        label="NIK" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="6">
                                    <p class="blue-lcd mb-0 pa-0">Active <strong style="color:red;">*</strong></p>
                                    <v-switch
                                        v-model="activeDialog"
                                        color="primary"
                                        hide-details
                                        inset
                                        dense
                                        true-value="1"
                                        false-value="0"
                                        @change="changeActiveFlag(item)"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col class="col-6" sm="6" md="2">
                                    <v-btn v-if="isNew === true && isUpdate === false" class="mt-5 mr-4 border-12"
                                        style="padding: 19px;" block small color="primary" elevation="2" @click="save()">Save Config</v-btn>
                                    <v-btn v-if="isNew === false && isUpdate === true" class="mt-5 mr-4 border-12"
                                        style="padding: 19px;" block small color="primary" elevation="2" @click="update()">Update Config</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-row>
            </v-dialog>
        </v-row>

        <v-row class="mb-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    item-class="tr_datatable"
                                    :items-per-page="20"
                                    fixed-header
                                    height="500"
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                >
                                    <!-- <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">List</p>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" @click="addNewConfig()"><v-icon>mdi-plus</v-icon> New </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template> -->
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <td>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="getHeader('update', item)" v-on="on">
                                                        mdi-pencil
                                                    </v-icon>
                                                </template>
                                                <span>Edit Config</span>
                                            </v-tooltip>
                                        </td>
                                    </template>
                                    <template v-slot:[`item.active_flag`]="{ item }">
                                        <v-switch
                                            v-model="item.active_flag"
                                            inset
                                            color="primary"
                                            class="pt-1"
                                            true-value="1"
                                            false-value="0"
                                            dense
                                            @change="changeActiveFlag(item)"
                                        ></v-switch>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { woAprvConfig } from "../../../backend-api/viva/maintenance/woAprvConfig";

export default {
    data() {
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Maintenance',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Maintenance Approval Config',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            mill:'',
            mills:[],
            isMillDisabled:false,
            machType:'',
            machTypes:[],
            isMachTypeDisabled:false,
            machId:'',
            machIds:[],
            isMachIdDisabled:false,
            section:'',
            sections:[],
            isSectionDisabled:false,
            level:'',
            levels:[
                {id: '1', descr: 'Diajukan'},
                {id: '2', descr: 'Disetujui'},
                {id: '3', descr: 'Diterima'},
                {id: '4', descr: 'QC'},
                {id: '5', descr: 'Diperiksa'}],
            active:'',
            actives:['Y', 'N', 'A'],

            searchItem:'',
            header:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Mill', value: 'mill_id'},
                { text: 'Section', value: 'section_id'},
                { text: 'Level', value: 'level'},
                { text: 'Nik', value: 'nik'},
                { text: 'Active', value: 'active_flag'},
            ],
            headersColumn:{
                'Mill No': 'mill_id',
                'Section': 'section_id',
                'Level': 'level',
                'Nik': 'nik',
                'Active': 'active_flag'
            },
            data:[],

            id:'',
            dialogConfig:false,
            dialogConfigTitle:'',
            isNew:false,
            isUpdate:false,
            millDialog:'',
            millsDialog:[],
            isMillDialogDisabled:false,
            sectionDialog:'',
            sectionsDialog:[],
            isSectionDialogDisabled:false,
            levelDialog:'',
            nikDialog:'',
            niksDialog:[],
            activeDialog:"1",
        }
    },
    props: {
        companyId: String,
    },
    async mounted(){},
    methods:{
        async initView(){
            this.$store.dispatch('setOverlay', true);
            await axios.get(`${process.env.VUE_APP_URL}/api/cek_token`, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            }).then(async response => {
                console.log("then")
                this.resetDialogGlobal()
                this.resetFilter()
                this.resetTable()
                this.breadcumbsAssign()
                await this.getMill()
                await this.getEmployee()
                this.$store.dispatch('setOverlay', false);
            }).catch(error => {
                this.$store.dispatch('setOverlay', false);
                // router.push({ path: "/" });
            })
        },
        breadcumbsAssign(){
            const mapping = {
                'SR': { text: 'Sunrise Steel', href: '/admin/sr' },
                'SM': { text: 'Sunrise Mill', href: '/admin/sm' },
                'KKA': { text: 'KKA', href: '/admin/kka' },
                'GBRK': { text: 'GBRK', href: '/admin/gbrk' },
            };
            const selectedMapping = mapping[this.companyId] || { text: '', href: '' };

            this.breadcumbs[0].text = selectedMapping.text;
            this.breadcumbs[0].href = selectedMapping.href;
        },
        resetFilter(){
            this.mill = ''
            this.mills = []
            this.isMillDisabled = false
            this.machType = ''
            this.machTypes = []
            this.isMachTypeDisabled = false
            this.machId = ''
            this.machIds = []
            this.isMachIdDisabled = false
            this.section = ''
            this.sections = []
            this.isSectionDisabled = false
            this.level = ''
            this.levels = ['1','2','3','4','5']
            this.active = ''
            this.actives = ['Y', 'N', 'A']
            this.data = []
        },
        resetDialogLocal(){
            this.id = ''
            this.dialogConfig = false
            this.dialogConfigTitle = ''
            this.millDialog =  ''
            this.isMillDialogDisabled = false
            this.sectionDialog =  ''
            this.isSectionDialogDisabled = false
            this.levelDialog =  ''
            this.nikDialog =  ''
            this.isNew = false
            this.isUpdate = false
            this.activeDialog = "1"
        },
        resetDialogGlobal(){
            this.millsDialog =  []
            this.sectionsDialog =  []
            this.niksDialog =  []
            this.resetDialogLocal()
        },
        resetTable(){
            this.searchItem = ''
            this.data = []
        },

        openDialog(event, data){
            if (event === 'new'){
                this.dialogConfigTitle = 'Add New Config'
                this.isNew = true
                this.isUpdate = false
            } else if (event === 'update'){
                this.dialogConfigTitle = 'Edit Config'
                this.isNew = false
                this.isUpdate = true
            } else {
                this.dialogConfigTitle = 'Config'
                this.isNew = false
                this.isUpdate = false
            }
            this.dialogConfig = true
        },

        closeDialog(event){
            this.resetDialogLocal()
        },

        async getMill(){
            let isSpecialCompany = ['KKA', 'GBRK'].includes(this.companyId);
            let $url = isSpecialCompany ? `?cd=${this.companyId}&active_flag=Y&mill_type=M` : `?cd=${this.companyId}&active_flag=Y`;

            const respData = await woAprvConfig.fetcMill($url)
            if (respData.status === 200) {
                this.mills = respData.data.data
                this.millsDialog = respData.data.data
                if(respData.data.data.length == 1){
                    this.mill = this.mills[0]['mill_id']
                    this.isMillDisabled = true
                    await this.getMachType()
                } else {
                    this.isMillDisabled = false
                }
            }
        },

        async getMachType(event){
            let $url = `?cd=${this.companyId}&active_flag=Y&mill_id=${this.mill}`;

            const respData = await woAprvConfig.fetcMachType($url)
            if (respData.status === 200) {
                this.machTypes = respData.data.data
                if(respData.data.data.length == 1){
                    this.machType = this.machTypes[0]['mach_type']
                    this.isMachTypeDisabled = true
                } else {
                    this.isMachTypeDisabled = false
                }
            }
        },

        async getMachId(event){
            let $url = `?cd=${this.companyId}&active_flag=Y&mill_id=${this.mill}&mach_type=${this.machType}`;

            const respData = await woAprvConfig.fetcMachId($url)
            if (respData.status === 200) {
                this.machIds = respData.data.data
                if(respData.data.data.length == 1){
                    this.machId = this.machIds[0]['mach_id']
                    this.isMachIdDisabled = true
                } else {
                    this.isMachIdDisabled = false
                }
            }
        },

        async getMaintSection(event){
            let reqBody = {
                cd: this.companyId,
                mill_id: this.mill,
                section_id: '',
                descr: '',
                active_flag: "Y",
                mach_type:[this.machType],
                mach_id:[this.machId]
            }

            const respData = await woAprvConfig.fetcMaintSection('', reqBody)
            if (respData.status === 200) {
                this.sections = respData.data.data
                if(respData.data.data.length == 1){
                    this.section = this.sections[0]['section_id']
                    this.isSectionDisabled = true
                } else {
                    this.isSectionDisabled = false
                }
            }
        },

        async getMaintSectionDialog(event, dt){
            let reqBody = {
                cd: this.companyId,
                mill_id: this.millDialog,
                section_id: dt,
                descr: '',
                active_flag: "Y",
                mach_type:[],
                mach_id:[]
            }

            const respData = await woAprvConfig.fetcMaintSection('', reqBody)
            if (respData.status === 200) {
                this.sectionsDialog = respData.data.data
                let a = this.sectionsDialog[0]['section_id'].trim()
                if(a == dt.trim() && respData.data.data.length) {
                    this.sectionDialog = this.sectionsDialog[0]['section_id']
                } else {
                    this.sectionDialog = dt || '';
                }
                
            }
        },

        async getEmployee(){
            // Todo disini error
            console.log('get employee')
            const companyConfig = {
                // 'TEST': { company: 'SRS', dept: 'MNT', div: '', sec: '' },
                'TEST': { company: 'SRS', dept: 'MNT'},
                'SR': { company: 'SRS', dept: 'MNT'},
                'SM': { company: 'SRM', dept: 'MNT'},
                'KKA': { company: 'KKA', dept: 'MNT'},
                'GBRK': { company: 'GBRK', dept: 'MNT'},
            };
            let val = companyConfig[this.companyId]
            let reqBody = {
                cd : this.companyId,
                company_id : [val.company],
                idemployee : [],
                office_id : [],
                dept_id : [val.dept],
                division_id : [],
                section_id : [],
                group_id : [],
                active_flag : ['Y'],
            }
            // let $url = `?cd=${this.companyId}&company_id=${vl.company}&idemployee=&office_id=&dept_id=${vl.dept}
            //     &division_id=${vl.div}&section_id=${vl.sec}&group_id&active_flag=Y`
            const respData = await woAprvConfig.fetcEmployee('', reqBody)
            if (respData.status === 200) {
                this.niksDialog = respData.data.data
            }
            
        },

        async getHeader(event, dt){
            this.dialogTitle = 'Project :'
            let reqBody = {};
            if (event === 'search'){
                reqBody = {
                    "cd" : this.companyId,
                    "id" : '',
                    "mill_id" : this.mill,
                    "section_id" : this.section,
                    "level" : this.level,
                    "nik" : '',
                    "active_flag" : this.active,
                }
            } else {
                reqBody = {
                    "cd" : this.companyId,
                    "id" : dt.id,
                    "mill_id" : '',
                    "section_id" : '',
                    "level" : '',
                    "nik" : '',
                    "active_flag" : '',
                }
            }
            console.log(event)
            console.log(dt)
            console.log(reqBody)
            this.$store.dispatch('setOverlay', true)
            const respData = await woAprvConfig.fetcIndex(``, reqBody, false, false, false)
            if (respData.status === 200) {
                if (event === 'search'){
                    this.data = respData.data.data
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    // TODO buka dialog
                    this.editApproval(event, respData.data.data[0])
                }
                
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },

        async editApproval(event, data){
            this.openDialog('update',[])
            this.id = data.id
            this.millDialog = data.mill_id
            this.isMillDialogDisabled = true
            this.isSectionDialogDisabled = true
            // TODO mungkin perlu dirubah
            this.levelDialog = data.level
            this.nikDialog = data.nik
            this.activeDialog = data.active_flag
            await this.getMaintSectionDialog(data, data.section_id)
            // this.sectionDialog = data.section_id
        },

        async save(event){
            if(this.millDialog === "" || this.sectionDialog === "" || this.levelDialog === "" || this.nikDialog === ""){
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                }
            } else {
                let reqBody = {
                    'cd': this.companyId,
                    'mill_id': this.millDialog,
                    "section_id": this.sectionDialog,
                    "level": this.levelDialog,
                    "nik": this.nikDialog,
                    "active_flag": this.activeDialog,
                    "created_by": this.$store.state.user.idemployee,
                    "modified_by": this.$store.state.user.idemployee
                }
                this.$store.dispatch('setOverlay', true)
                const respData = await woAprvConfig.store('', reqBody, false, false, false)
                if (respData.status != 200) {
                    this.$store.dispatch('setOverlay', false)
                    
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    return false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    await this.getHeader('search',[])
                    this.closeDialog()
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: `Configuration had been created`,
                        visible: true
                    };
                }
            }
        },

        async update(event){
            if(this.millDialog === "" || this.sectionDialog === "" || this.levelDialog === "" || this.nikDialog === ""){
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                }
            } else {
                let reqBody = {
                    'id': this.id,
                    'cd': this.companyId,
                    'mill_id': this.millDialog,
                    "section_id": this.sectionDialog,
                    "level": this.levelDialog,
                    "nik": this.nikDialog,
                    "active_flag": this.activeDialog,
                    "created_by": this.$store.state.user.idemployee,
                    "modified_by": this.$store.state.user.idemployee
                }
                this.$store.dispatch('setOverlay', true)
                const respData = await woAprvConfig.update('', reqBody, false, false, false)
                if (respData.status != 200) {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    return false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    await this.getHeader('search',[])
                    this.closeDialog()
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: `Configuration had been updated`,
                        visible: true
                    };
                }
            }
        },

        async changeActiveFlag(event){
            console.log('switch')
            console.log(event)
            let reqBody = {
                    'id': event.id,
                    'cd': this.companyId,
                    'mill_id': event.mill_id,
                    "section_id": event.section_id,
                    "level": event.level,
                    "nik": event.nik,
                    "active_flag": event.active_flag,
                    "created_by": this.$store.state.user.idemployee,
                    "modified_by": this.$store.state.user.idemployee
                }
                this.$store.dispatch('setOverlay', true)
                const respData = await woAprvConfig.update('', reqBody, false, false, false)
                if (respData.status != 200) {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    return false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    await this.getHeader('search',[])
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: `Configuration had been deactivated`,
                        visible: true
                    };
                }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(async vm => {
            await vm.initView()
        });
    },

    beforeRouteLeave(to, from, next) {
        this.resetDialogGlobal()
        this.resetFilter()
        this.resetTable()
        next()
    },
}
</script>